<template>
  <b-card>
<!--    <loader/>-->
    <b-row v-if="role_id===4">
      <b-col lg="4">
        <b-card-group deck>
          <b-card>
            <div class="text-center">
              <b-avatar :src="selected_row.image?imgShow(selected_row.image):'/student_face.gif'" size="6rem"></b-avatar>
              <h1 class="mb-0">{{ userData.name }}</h1>
              <h6 class="my-2">Student</h6>
            </div>
            <table>
              <tr>
                <td class="font-weight-bold">Cadet No</td>
                <td>: {{ selected_row.cadet_no }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Role</td>
                <td>: {{ userData.role?userData.role.name:'' }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Username</td>
                <td>: {{ userData.user_name }}</td>
              </tr>
              <tr>
                <td>Wing</td>
                <td>: {{ wingByID(selected_row.wing_id) }}</td>
              </tr>
            </table>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col lg="8">
        <b-card>
          <b-tabs pills card>
            <b-tab title="Profile" active>
              <b-card-body>
                <b-row>
                  <b-col md="7">
                    <table>
                      <tr v-if="selected_row.roll">
                        <td>Roll</td>
                        <td>: {{ selected_row.roll }}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>: {{ userData.email }}</td>
                      </tr>
                      <tr>
                        <td>Phone number</td>
                        <td>: {{ userData.phone_number }}</td>
                      </tr>
                      <tr>
                        <td>Gender</td>
                        <td>: {{ genderById(userData.gender_id) }}</td>
                      </tr>
                      <tr>
                        <td>Blood group</td>
                        <td>:{{ bloodGroupById(userData.blood_group_id) }}</td>
                      </tr>
                      <tr>
                        <td>Religion</td>
                        <td>: {{ religionById(userData.religion_id) }}</td>
                      </tr>
                      <tr>
                        <td>Version</td>
                        <td>: {{ versionByID(selected_row.version_id) }}</td>
                      </tr>
                      <tr>
                        <td>Shift</td>
                        <td>: {{ shiftByID(selected_row.shift_id) }}</td>
                      </tr>
                    </table>

                  </b-col>
                  <b-col md="5">
                    <table>
                      <tr>
                        <td>Class</td>
                        <td>: {{ classByID(selected_row.classes_id) }}</td>
                      </tr>
                      <tr>
                        <td>Student Group</td>
                        <td>: {{ student_groupByID(selected_row.student_group_id) }}</td>
                      </tr>
                      <tr>
                        <td>Section</td>
                        <td>: {{ sectionById(selected_row.section_id) }}</td>
                      </tr>
                      <tr>
                        <td>Academic year</td>
                        <td>: {{ academic_yearByID(selected_row.academic_year_id) }}</td>
                      </tr>
                      <tr>
                        <td>Special promotion</td>
                        <td :style="selected_row.is_special_promotion?'color:green':'color:red'">:
                          {{ (selected_row.is_special_promotion === 1) ? 'yes' : 'no' }}
                        </td>
                      </tr>
                      <tr>
                        <td>Date of birth</td>
                        <td>: {{ selected_row.date_of_birth }}</td>
                      </tr>
                      <!-- <tr>
                        <td>Status</td>
                        <td :style="selected_row.is_active?'color:green':'color:red'">:
                          {{ (selected_row.is_active === 1) ? 'active' : 'inactive' }}
                        </td>
                      </tr> -->
                    </table>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
            <b-tab title="Parent">
              <b-card-body>
                <b-row>
                  <b-col md="7">
                    <table>
                      <tr>
                        <td>Father Name</td>
                        <td>: {{ (selected_row.parent) ? selected_row.parent.father_name : '' }}</td>
                      </tr>
                      <tr>
                        <td>Father Profession</td>
                        <td>: {{ (selected_row.parent) ? selected_row.parent.father_profession : '' }}</td>
                      </tr>
                      <tr>
                        <td style="width:120px">Father Phone Number</td>
                        <td>: {{ (selected_row.parent) ? selected_row.parent.father_phone_number : '' }}</td>
                      </tr>
                      <tr>
                        <td>Father NID</td>
                        <td>: {{ (selected_row.parent) ? selected_row.parent.father_nid_no : '' }}</td>
                      </tr>
                      <tr v-if="selected_row.parent ? selected_row.parent.father_email: '' ">
                        <td>Father Email</td>
                        <td>: {{ (selected_row.parent) ? selected_row.parent.father_email : '' }}</td>
                      </tr>
                      <tr>
                        <td>Mother Name</td>
                        <td>: {{ (selected_row.parent) ? selected_row.parent.mother_name : '' }}</td>
                      </tr>
                      <tr>
                        <td>Mother Profession</td>
                        <td>: {{ (selected_row.parent) ? selected_row.parent.mother_profession : '' }}</td>
                      </tr>
                      <tr>
                        <td style="width:120px">Mother Phone Number</td>
                        <td>: {{ (selected_row.parent) ? selected_row.parent.mother_phone_number : '' }}</td>
                      </tr>
                      <tr>
                        <td>Mother NID</td>
                        <td>: {{ (selected_row.parent) ? selected_row.parent.mother_nid_no : '' }}</td>
                      </tr>
                    </table>
                  </b-col>
                  <b-col md="5">
                    <table>
                      <tr v-if="selected_row.parent ? selected_row.parent.mother_email: '' ">
                        <td>Mother Email</td>
                        <td>: {{ (selected_row.parent) ? selected_row.parent.mother_email : '' }}</td>
                      </tr>
                      <tr>
                        <td>Present Address</td>
                        <td style="min-width:120px">: {{
                            (selected_row.parent) ? selected_row.parent.present_address : ''
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Permanent Address</td>
                        <td>: {{ (selected_row.parent) ? selected_row.parent.permanent_address : '' }}</td>
                      </tr>
                      <tr>
                        <td>Local Guardian Name</td>
                        <td>: {{ (selected_row.parent) ? selected_row.parent.local_guardian_name : '' }}</td>
                      </tr>
                      <tr>
                        <td>Local Guardian Phone</td>
                        <td>: {{ (selected_row.parent) ? selected_row.parent.local_guardian_phone_number : '' }}</td>
                      </tr>
                      <tr>
                        <td>Relation</td>
                        <td>: {{ (selected_row.parent) ? selected_row.parent.relation : '' }}</td>
                      </tr>
                      <tr>
                        <td>Local Guardian Address</td>
                        <td>: {{ (selected_row.parent) ? selected_row.parent.local_guardian_address : '' }}</td>
                      </tr>
                      <!-- <tr>
                        <td>Status</td>
                        <td :style="selected_row.parent?selected_row.parent.is_active?'color:green':'color:red':'color:red'">:
                          {{ selected_row.parent ? selected_row.parent.is_active ? 'active' : 'Inactive' : 'Inactive' }}
                        </td>
                      </tr> -->
                    </table>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
            <b-tab title="Password Change">
              <b-card-body>
                <!-- form -->
                <validation-observer ref="simpleRules">
                  <b-form ref="password_change_form" action="javascript:void(0)" method="post" @submit="changePassword()">
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                              #default="{ errors }"
                              name="Old Password"
                              rules="required"
                          >
                            <b-form-input type="password"
                                          v-model="old_password"
                                          name="old_password"
                                          :state="errors.length > 0 ? false:null"
                                          placeholder="Old password"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                              #default="{ errors }"
                              name="new password"
                              rules="required"
                          >
                            <b-form-input type="password"
                                          v-model="password"
                                          name="password"
                                          :state="errors.length > 0 ? false:null"
                                          placeholder="New password"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                              #default="{ errors }"
                              name="Confirm Password"
                              rules="required"
                          >
                            <b-form-input type="password"
                                          v-model="password_confirmation"
                                          name="password_confirmation"
                                          :state="errors.length > 0 ? false:null"
                                          placeholder="Confirm Password"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- submit button -->
                      <b-col>
                        <b-button
                            variant="primary"
                            type="submit"
                        >
                          Submit
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-card-body>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-else-if="role_id===5">
      <b-col lg="4">
        <b-card-group deck>
          <b-card>
            <div class="text-center">
              <b-avatar :src="selected_row.father_image?imgShow(selected_row.father_image):'/student_face.gif'" size="6rem"></b-avatar>
              <b-avatar :src="selected_row.mother_image?imgShow(selected_row.mother_image):'/student_face.gif'" size="6rem"></b-avatar>
              <h1 class="mb-0">Parents</h1>
              <h6 class="my-2"></h6>
            </div>
            <table>
              <tr>
                <td class="font-weight-bold">Role</td>
                <td>: {{ userData.role?userData.role.name:'' }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Username</td>
                <td>: {{ userData.user_name }}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>: {{ userData.email }}</td>
              </tr>
              <tr>
                <td>Phone Number</td>
                <td>: {{ userData.phone_number }}</td>
              </tr>
            </table>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col lg="8">
        <b-card>
          <b-tabs pills card>
            <b-tab title="profile" active>
              <b-card-body>
                <b-row>
                  <b-col md="6">
                    <table>
                      <tr>
                        <td>Father Name</td>
                        <td>: {{ selected_row.father_name }}</td>
                      </tr>
                      <tr>
                        <td>Father Profession</td>
                        <td>: {{ selected_row.father_profession }}</td>
                      </tr>
                      <tr>
                        <td style="width:120px">Father Phone Number</td>
                        <td>: {{ selected_row.father_phone_number }}</td>
                      </tr>
                      <tr>
                        <td>Father NID</td>
                        <td>: {{ selected_row.father_nid_no }}</td>
                      </tr>
                      <tr v-if="selected_row.father_email ">
                        <td>Father Email</td>
                        <td>: {{selected_row.father_email }}</td>
                      </tr>
                      <tr>
                        <td>Mother Name</td>
                        <td>: {{ selected_row.mother_name }}</td>
                      </tr>
                      <tr>
                        <td>Mother Profession</td>
                        <td>: {{ selected_row.mother_profession }}</td>
                      </tr>
                      <tr>
                        <td style="width:120px">Mother Phone Number</td>
                        <td>: {{ selected_row.mother_phone_number }}</td>
                      </tr>
                      <tr>
                        <td>Mother NID</td>
                        <td>: {{ selected_row.mother_nid_no }}</td>
                      </tr>
                    </table>
                  </b-col>
                  <b-col md="6">
                    <table>
                      <tr v-if="selected_row.mother_email ">
                        <td>Mother Email</td>
                        <td>: {{ selected_row.mother_email }}</td>
                      </tr>
                      <tr>
                        <td>Present Address</td>
                        <td style="min-width:120px">: {{selected_row.present_address}}</td>
                      </tr>
                      <tr>
                        <td>Permanent Address</td>
                        <td>: {{ selected_row.permanent_address }}</td>
                      </tr>
                      <tr>
                        <td>Local Guardian Name</td>
                        <td>: {{ selected_row.local_guardian_name }}</td>
                      </tr>
                      <tr>
                        <td>Local Guardian Phone</td>
                        <td>: {{ selected_row.local_guardian_phone_number }}</td>
                      </tr>
                      <tr>
                        <td>Relation</td>
                        <td>: {{ selected_row.relation }}</td>
                      </tr>
                      <tr>
                        <td>Local Guardian Address</td>
                        <td>: {{ selected_row.local_guardian_address }}</td>
                      </tr>
                      <!-- <tr>
                        <td>Status</td>
                        <td :style="selected_row.is_active?'color:green':'color:red'">:
                          {{ selected_row.is_active ? 'active' : 'Inactive' }}
                        </td>
                      </tr> -->
                    </table>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
            <b-tab title="Password Change">
              <b-card-body>
                <!-- form -->
                <validation-observer ref="simpleRules">
                  <b-form ref="password_change_form" action="javascript:void(0)" method="post" @submit="changePassword()">
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                              #default="{ errors }"
                              name="Old Password"
                              rules="required"
                          >
                            <b-form-input type="password"
                                          v-model="old_password"
                                          name="old_password"
                                          :state="errors.length > 0 ? false:null"
                                          placeholder="old password"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                              #default="{ errors }"
                              name="new password"
                              rules="required"
                          >
                            <b-form-input type="password"
                                          v-model="password"
                                          name="password"
                                          :state="errors.length > 0 ? false:null"
                                          placeholder="new password"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                              #default="{ errors }"
                              name="Confirm Password"
                              rules="required"
                          >
                            <b-form-input type="password"
                                          v-model="password_confirmation"
                                          name="password_confirmation"
                                          :state="errors.length > 0 ? false:null"
                                          placeholder="Confirm Password"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- submit button -->
                      <b-col>
                        <b-button
                            variant="primary"
                            type="submit"
                        >
                          Submit
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-card-body>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-else-if="role_id !==4 || role_id !==5">
      <b-col lg="4">
        <b-card-group deck>
          <b-card>
            <div class="text-center">
              <b-avatar :src="selected_row.image?imgShow(selected_row.image):'/student_face.gif'" size="6rem"></b-avatar>
              <h1 class="mb-0">{{userData.name}}</h1>
              <h6 class="my-2">Employee</h6>
            </div>
            <table>
              <tr>
                <td class="font-weight-bold">Employee Id</td>
                <td>: {{selected_row?selected_row.institute_emp_id:'TBD'}}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Role</td>
                <td>: {{userData.role?userData.role.name:''}}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Designation </td>
                <td>: {{designationByID(selected_row.designation_id)?designationByID(selected_row.designation_id):'TBD'}}</td>
              </tr>
            </table>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col lg="8">
        <b-card>
          <b-tabs pills card>
            <b-tab title="Profile" active >
              <b-card-body>
                <b-row>
                  <b-col  md="7">
                    <table>
                      <tr>
                        <td> Email</td>
                        <td>:{{userData.email}}</td>
                      </tr>
                      <tr>
                        <td> Phone number</td>
                        <td>:{{userData.phone_number?userData.phone_number:'TBD'}}</td>
                      </tr>
                      <tr>
                        <td>Gender</td>
                        <td>:{{genderById(userData.gender_id)?genderById(userData.gender_id):'TBD'}}</td>
                      </tr>
                      <tr>
                        <td>Blood group</td>
                        <td>:{{bloodGroupById((userData.blood_group_id))?bloodGroupById((userData.blood_group_id)):'TBD'}}</td>
                      </tr>
                      <tr>
                        <td>Religion</td>
                        <td>:{{religionById((userData.religion_id))?religionById((userData.religion_id)):'TBD'}}</td>
                      </tr>
                      <tr>
                        <td>Emergency contact</td>
                        <td>:{{selected_row.emergency_contact?selected_row.emergency_contact:'TBD'}}</td>
                      </tr>
                    </table>

                  </b-col>
                  <b-col  md="5">
                    <table>
                      <tr>
                        <td>Father Name </td>
                        <td>:{{selected_row.father_name?selected_row.father_name:'TBD'}}</td>
                      </tr>
                      <tr>
                        <td>Mother Name</td>
                        <td>:{{selected_row.mother_name?selected_row.mother_name:'TBD'}}</td>
                      </tr>
                      <tr>
                        <td>NID</td>
                        <td>:{{selected_row.nid?selected_row.nid:'TBD'}}</td>
                      </tr>
                      <tr>
                        <td>Marital status</td>
                        <td>:{{maritalStatusById(selected_row.marital_status_id)?maritalStatusById(selected_row.marital_status_id):'TBD'}}</td>
                      </tr>

                      <tr>
                        <td>Present address</td>
                        <td>:{{selected_row.present_address?selected_row.present_address:'TBD'}}</td>
                      </tr>
                      <tr>
                        <td>Permanent address</td>
                        <td>:{{selected_row.permanent_address?selected_row.permanent_address:'TBD'}}</td>
                      </tr>
                    </table>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
            <b-tab title="Employment">
              <b-card-body>
                <b-row>
                  <b-col md="7">
                    <table>
                      <tr>
                        <td>Designation</td>
                        <td>: {{designationByID(selected_row.designation_id)?designationByID(selected_row.designation_id):'TBD'}}</td>
                      </tr>
                      <tr>
                        <b-card-text>Scale</b-card-text>
                        <td>: {{scaleByID(selected_row.scale_id)?scaleByID(selected_row.scale_id):'TBD'}}</td>
                      </tr>
                      <tr>
                        <td>Designation Scale</td>
                        <td>: {{designationScaleByID(selected_row.designation_scale_id)?designationScaleByID(selected_row.designation_scale_id):'TBD'}}</td>
                      </tr>
                      <tr>
                        <td>Version </td>
                        <td>: {{versionByID(selected_row.version_id)?versionByID(selected_row.version_id):'TBD'}}</td>
                      </tr>
                      <tr>
                        <td>Shift</td>
                        <td>: {{shiftByID(selected_row.shift_id)?shiftByID(selected_row.shift_id):'TBD'}}</td>
                      </tr>
                    </table>
                  </b-col>
                  <b-col md="5">
                    <table>
                      <tr>
                        <td>Department</td>
                        <td>: {{departmentByID(selected_row.department_id)?departmentByID(selected_row.department_id):'TBD'}}</td>
                      </tr>
                      <tr>
                        <td>Wing</td>
                        <td>: {{wingByID(selected_row.wing_id)?wingByID(selected_row.wing_id):'TBD'}}</td>
                      </tr>
                      <tr>
                        <td>Join date</td>
                        <td>: {{selected_row.join_date?selected_row.join_date:'TBD'}}</td>
                      </tr>
                      <tr v-if="selected_row.leave_date">
                        <td>Leave date</td>
                        <td>: {{selected_row.leave_date?selected_row.leave_date:'TBD' }}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td :style="selected_row.is_active?'color:green':'color:red'">: {{(selected_row.is_active === 1) ? 'active': 'inactive' }}</td>
                      </tr>
                    </table>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
            <b-tab title="Password Change">
              <b-card-body>
                <!-- form -->
                <validation-observer ref="simpleRules">
                  <b-form ref="password_change_form" action="javascript:void(0)" method="post" @submit="changePassword()">
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                              #default="{ errors }"
                              name="Old Password"
                              rules="required"
                          >
                            <b-form-input type="password"
                                v-model="old_password"
                                name="old_password"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Old password"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                              #default="{ errors }"
                              name="new password"
                              rules="required"
                          >
                            <b-form-input type="password"
                                v-model="password"
                                name="password"
                                :state="errors.length > 0 ? false:null"
                                placeholder="New password"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                              #default="{ errors }"
                              name="Confirm Password"
                              rules="required"
                          >
                            <b-form-input type="password"
                                v-model="password_confirmation"
                                name="password_confirmation"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Confirm Password"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- submit button -->
                      <b-col>
                        <b-button
                            variant="primary"
                            type="submit"
                        >
                          Submit
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-card-body>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
//import Loader from "@/views/Loader";
import {
  BCard, BAvatar, BRow, BCol,BCardGroup, BCardBody, BTabs, BTab,BCardText,
    BForm,BFormGroup,BButton,BFormInput,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import apiCall from "@/libs/axios";
import {mapGetters} from "vuex";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
export default {
  components: {
    BCard, BRow, BCol, BAvatar,BCardGroup, BCardBody, BTabs, BTab,BCardText,
    ValidationProvider, ValidationObserver,BForm,BFormGroup,BButton,BFormInput,
    //Loader
  },
  data(){
    return{
      userData:{},
      selected_row:{},
      role_id:null,
      old_password:null,
      password:null,
      password_confirmation:null,
    }
  },
  methods:{
    async getInfo(){
      await apiCall.get('/get/current/user/info').then((response)=>{
        this.userData=response.data;
        this.selected_row=this.userData.userable;
      }).catch(()=>{
        //console.log('not found');
      })
    },
    imgShow(path) {
      return process.env.VUE_APP_ENV_RESOURCHURL + path;
    },
    changePassword(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.password_change_form);
          apiCall.post('/current/user/password/change', data).then((response)=>{
            if(response.data.status==='success'){
              this.$toaster.success(response.data.message);
              this.old_password=null;
              this.password=null;
              this.password_confirmation=null;
              this.$refs.simpleRules.reset();
            }else this.$toaster.error(response.data.message);

          }).catch((error)=>{
            if (error.response.status == 422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            }
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    }
  },
  created() {
    this.getInfo();
    if (this.versions.length <1 ) this.$store.dispatch('GET_ALL_VERSION');
    if (this.shifts.length <1 ) this.$store.dispatch('GET_ALL_SHIFT');
    if (this.departments.length <1 ) this.$store.dispatch('GET_ALL_DEPARTMENT');
    if (this.designations.length <1 ) this.$store.dispatch('GET_ALL_DESIGNATION');
    if (this.scales.length <1 ) this.$store.dispatch('GET_ALL_SCALE');
    if (this.designation_scales.length <1 ) this.$store.dispatch('GET_ALL_DESIGNATION_SCALE');
    if (this.wings.length <1 ) this.$store.dispatch('GET_ALL_WING');
    if(this.religions.length<1) this.$store.dispatch('GET_ALL_RELIGION');
    if(this.blood_groups.length<1) this.$store.dispatch('GET_ALL_BLOOD_GROUP');
    if(this.genders.length<1) this.$store.dispatch('GET_ALL_GENDER');
    if(this.marital_statuses.length<1) this.$store.dispatch('GET_ALL_MARITAL_STATUS');
    if(this.sections.length<1) this.$store.dispatch('GET_ALL_SECTION');
    if(this.academic_years.length<1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    if(this.classes.length<1) this.$store.dispatch('GET_ALL_CLASSES');
    if(this.student_groups.length<1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
  },
  computed: {
    ...mapGetters([
      'versions','versionByID','shifts','shiftByID','departments','departmentByID',
      'designations','designationByID','scales','scaleByID','designation_scales','designationScaleByID',
      'religions', 'religionById','genders','genderById','blood_groups','bloodGroupById','wings',
      'wingByID','marital_statuses','maritalStatusById','sections','sectionById','academic_years','academic_yearByID',
        'classes','classByID','student_groups','student_groupByID', 'getEmployeeId'
    ]),
  },
  setup() {
    return {
      avatarText,
    }
  },
  watch: {
    userData(){
      this.role_id=this.userData.role_id;
    }
  },
}
</script>

<style>

</style>
